@import '../../styles/Variables.scss';

.e-recents-list {
    .e-recents-list-item {
        cursor: pointer;
        transition-duration: 232ms;
        color: black !important;
        padding: 12px 24px;
        border: 3px solid darken($color: white, $amount: 20);
        box-shadow: 0 4px 0px darken($color: white, $amount: 20) !important;
        border-radius: 12px;
        background-color: white;

        &:hover, &:focus {
            border-color: $primary;
            border: 3px solid darken($color: $primary, $amount: 20);
            box-shadow: 0 4px 0px darken($color: $primary, $amount: 20) !important;
            outline: none;
        }
    }

    .e-recents-list-item-remove {
        color: $secondary;
        
        &:hover {
            color: $red-500;
        }
    }
}

.e-pagination {
    background-color: darken($color: #EFE9E4, $amount: 5) !important;
    border-radius: 12px;

    .e-page-btn {
        margin-right: 12px;
        font-weight: bold;
        color: $secondary !important;
        border-radius: 8px !important;
        background-color: #EFE9E4 !important;
        border: 3px solid darken($color: #EFE9E4, $amount: 20) !important;
        box-shadow: 0 3px 0px darken($color: #EFE9E4, $amount: 20) !important;

        &.active {
            background-color: $dark-alt !important;
            box-shadow: 0 2px 0px darken($color: $dark-alt, $amount: 20) !important;
            border-color: $dark-alt !important;
            pointer-events: none;
        }

        &:hover {
            border-color: $secondary !important;
            box-shadow: 0 2px 0px $secondary !important;
        }
    }
}


.e-btn-menu {
    border-radius: 12px !important;
    color: $secondary !important;

    &:hover {
        background-color: $secondary !important;
        color: $alt !important;
    }

    &:focus {
        box-shadow: 0 0 0 0.25rem $alt !important;
    }
}