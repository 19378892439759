@import "../../styles/Variables.scss";

.e-input {
    border-radius: 12px;
    background-color: darken($color: white, $amount: 10);
    border: 2px solid $light;
    padding: 18px 24px;
    color: black;
    font-weight: bold;
    transition-duration: 432ms;

    &:focus {
        border-color: black;
        outline: none !important;
        color: black;
        background-color: white;
        box-shadow: inset 0 0 2px #000000;
    }

    &.valid {
        color: $green;
    }
}

.e-btn-console {
    background-color: $primary !important;
    border-radius: 12px !important;
    border: 2px solid darken($color: $primary, $amount: 20) !important;
    box-shadow: 0px 4px 0px darken($color: $primary, $amount: 20) !important;

    &:hover {
        background-color: darken($color: $primary, $amount: 10) !important;
        border-color: darken($color: $primary, $amount: 10) !important;
        box-shadow: unset !important;
    }
}

.e-btn-register {
    background-color: lighten($color: $dark, $amount: 30) !important;
    border-radius: 12px !important;
    color: $light !important;
    border: 2px solid lighten($color: $dark, $amount: 20) !important;
    box-shadow: 0px 4px 0px lighten($color: $dark, $amount: 20) !important;

    &:disabled,
    &.disabled {
        color: darken($color: white, $amount: 40) !important;
        background-color: unset !important;
        border-color: darken($color: white, $amount: 10) !important;
        box-shadow: 0px 4px 0px darken($color: white, $amount: 10) !important;
    }

    &:hover {
        color: darken($color: $light, $amount: 5) !important;
        background-color: darken($color: $dark, $amount: 30) !important;
        border-color: darken($color: $dark, $amount: 30) !important;
        box-shadow: 0px 1px 0px lighten($color: $dark, $amount: 20) !important;
    }
}

.e-btn-send {
    background-color: $primary !important;
    border-radius: 12px !important;
    color: $light !important;
    border: 2px solid darken($color: $primary, $amount: 20) !important;
    box-shadow: 0px 4px 0px darken($color: $primary, $amount: 20) !important;

    &:disabled,
    &.disabled {
        color: darken($color: white, $amount: 40) !important;
        background-color: unset !important;
        border-color: darken($color: white, $amount: 10) !important;
        box-shadow: 0px 4px 0px darken($color: white, $amount: 10) !important;
    }

    &:hover {
        color: $light !important;
        background-color: darken($color: $primary, $amount: 5) !important;
        border-color: darken($color: $primary, $amount: 5) !important;
        box-shadow: 0px 1px 0px lighten($color: $primary, $amount: 5) !important;
    }
}

.e-btn-nav {
    &:hover {
        background-color: darken($color: $light, $amount: 5) !important;
    }

    &.active {
        background-color: lighten($color: $primary, $amount: 30) !important;
        border: 1px solid $primary;
        color: darken($color: $primary, $amount: 20);
    }
}
