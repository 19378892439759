@import './styles/Variables.scss'; // Custom variables

@import '~uikit/src/scss/variables.scss'; // UIKit 1/3
@import '~uikit/src/scss/mixins.scss'; // UIKit 2/3
@import '~uikit/src/scss/uikit.scss'; // UIKit 3/3

@import '~bootstrap/scss/bootstrap.scss'; // Bootstrap

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $light;
}

h1, h2, h3, h4, h5, h6 {
  // font-family: 'Jost', sans-serif;
  font-weight: 800 !important;
}

a {
  font-weight: 800 !important;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


// Global styles
.text-black {
  color: black !important;
}

.text-yellow {
  color: $yellow-500 !important;
}

.badge {
  border-radius: 12px !important;
}


.e-color-on-hover {
  transition-duration: 1s;
  filter: grayscale(1);
  
  &:hover {
      transition-duration: 1s;
      filter: unset;
  }
}


.e-border {
  background-color: darken($color:white, $amount: 20);
  height: 4px;
  border: none;
}
