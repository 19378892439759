@import '../styles/Variables.scss'; // Custom variables

.e-modal {
    background: white;
    border-radius: 12px;
}

.e-modal-anim {
    animation: myScaleAnim 322ms 1;
    animation-timing-function: cubic-bezier(0.23, 1, 0.320, 1);
}
@keyframes myScaleAnim {
    from {
        transform: scale(0.88);
    }
    to {
        // opacity: 1;
        transform: scale(1);
    }
}

.e-back {
    color: darken($color: $light, $amount: 30);
    border: 2px solid darken($color: $light, $amount: 10) !important;
    background: white;
    transition-duration: 243ms;

    &:hover, &:focus {
        color: $dark-alt;
        background-color: $light;
        outline: none;
    }
}


.e-download {
    padding: 10px 24px;
    border-radius: 12px !important;
    font-weight: 800;
    text-decoration: none;
    color: white !important;
}
.e-download-enunciado {
    @extend .e-download;
    background-color: $primary !important;
    box-shadow: 0 5px 0px darken($color: $primary, $amount: 10) !important;

    &:hover, &:focus {
        color: white !important;
        background-color: darken($color: $primary, $amount: 20) !important;
        box-shadow: 0 4px 0px darken($color: $primary, $amount: 10) !important;
    }

    &.disabled {
        color: white !important;
        opacity: 0.24 !important;
    }
}
.e-download-guia {
    @extend .e-download;
    background-color: $warning !important;
    box-shadow: 0 5px 0px darken($color: $warning, $amount: 10) !important;

    &:hover, &:focus {
        color: white !important;
        background-color: darken($color: $warning, $amount: 20) !important;
        box-shadow: 0 4px 0px darken($color: $warning, $amount: 10) !important;
    }

    &.disabled {
        color: white !important;
        opacity: 0.24 !important;
    }
}