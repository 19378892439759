@import '../../styles/Variables.scss'; // Custom variables

.bg-main {
    background-color: $light;
}

.bg-sections {
    background-color: $dark;

    &.recentes {
        border-left: 2px solid #F1DABF;
        background-color: #F0E9E3 !important;
    }

    &.favoritos {
        background-color: #F1DABF !important;
    }


    @include media-breakpoint-down(lg) {
        &__recents {
            border-top: 2px solid #F1DABF;
            background-color: #F0E9E3;
        }
    }
}


.e-playstore {
    border: 2px solid #eee !important;
    border-radius: 12px !important;
    transition-duration: 234ms;

    &:hover {
        border-color: $primary !important;
    }
}


.e-btn-toggle {
    color: $secondary !important;
    background-color: $alt !important;
    border: 2px solid $dark-alt !important;
    box-shadow: 0 4px 0px $dark-alt !important;
    border-radius: 8px !important;
    transition-duration: 234ms;

    &:hover {
        border-color: darken($color: $alt, $amount: 10) !important;
        background-color: darken($color: $alt, $amount: 10) !important;
        box-shadow: 0 3px 0px $dark-alt !important;
    }

    &.active {
        color: $light !important;
        background-color: $dark-alt !important;
        border-color: $dark-alt !important;
        box-shadow: 0 1px 0 darken($color: $dark-alt, $amount: 20) !important;

        &:focus {
            box-shadow: 0 1px 0px darken($color: $dark-alt, $amount: 20) !important;
        }
    }

    &:focus {
        border-color: darken($color: $dark-alt, $amount: 20) !important;
        box-shadow: 0 3px 0px darken($color: $dark-alt, $amount: 20) !important;
    }
}



.e-tab-item {
    padding: 24px 32px;
    color: black;
    border-radius: 12px !important;
    transition-duration: 234ms;
    border: 2px solid #e4e3e3;
    background-color: $light;
    cursor: pointer;

    &.active {
        transition-duration: 432ms;
        color: white;
        background: $dark;  /* fallback for old browsers */
        background-image: radial-gradient( circle 426px at 30% 50%,  #2b4136 0%, $dark 90% );
        border-color: lighten($color: $primary, $amount: 20);

        &:hover {
            border-color: lighten($color: $primary, $amount: 20);
        }
    }

    &:hover {
        background-color: lighten($color: $primary, $amount: 30);
        border-color: $primary;
    }

    &:focus {
        background-color: lighten($color: $primary, $amount: 20);
        border-color: $primary;
        outline: none !important;
    }
}