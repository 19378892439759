@import '../../styles/Variables.scss'; // Custom variables

.e-card {
    background-color: $dark;
    border-radius: 24px;
}

.e-black-gradient {
    background: $dark;
    background: -webkit-linear-gradient(to right, $dark, #434343);
    background: linear-gradient(to right, $dark, #434343);
}

.e-green-gradient {
    background: #34e89e;
    background: -webkit-linear-gradient(to right, #0f3443, #34e89e);
    background: linear-gradient(to right, #0f3443, #34e89e);
}

.e-blue-gradient {
    background: #000046;
    background: -webkit-linear-gradient(to right, #1CB5E0, #000046);
    background: linear-gradient(to right, #1CB5E0, #000046);
}

.e-darkblue-gradient {
    background: #0F2027;
    background: -webkit-linear-gradient(to right, #2C5364, #203A43, #0F2027);
    background: linear-gradient(to right, #2C5364, #203A43, #0F2027);
}


.e-nobackground {
    background: transparent !important;
    padding: 12px !important;
}

.e-btn-back {
    color: black !important;
    background-color: lighten($color: $primary, $amount: 30) !important;
    border: 2px solid $primary !important;
    box-shadow: 0 4px 0px $primary !important;
    transition-duration: 234ms;

    &:hover {
        border-color: lighten($color: $primary, $amount: 10) !important;
        background-color: lighten($color: $primary, $amount: 10) !important;
        box-shadow: 0 3px 0px $primary !important;
    }
}