// Modify existing colors from the original theme.
$primary: #6DD594; // app green
$warning: #fd7e14; // orange-500
$secondary: #43250E; // dark brown
$dark:  #1D1F23; // a very dark grey. quite black.
$light:  #F5F5F5; // very light grey.
// new
$alt: #F6D9BC;
$dark-alt: darken($color: $alt, $amount: 20); // #E0A96D

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

// Create new colors for the themem {prefix}-{color}; E.g: text-{color} or btn-{color}
$new-colors: (
    "alt": $alt,
    "dark-alt": $dark-alt
);
$theme-colors: map-merge($theme-colors, $new-colors);