@import "../styles/Variables.scss";

.e-options {
    .e-options-item {
        transition-duration: 234ms;
        border: 2px solid lighten($color: #252930, $amount: 10);
        padding: 6px 18px;
        font-weight: 600;
        color: lighten($color: #252930, $amount: 70);
        cursor: pointer;

        &.active {
            color: white;
            background-color: $primary;
            border-color: $primary;

            &:hover {
                transition-duration: 234ms;
                border-color: $primary;
                background-color: darken($color: $primary, $amount: 10);
            }
        }

        &:hover, &:focus {
            transition-duration: 234ms;
            border-color: $green;
            outline: none;
        }
    }
}